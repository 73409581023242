import {
  ReviseDailyDiaryItemMutation,
  ReviseDailyDiaryItemMutationVariables,
  SendAuthorizationAcceptanceWithResultMutation,
  SendAuthorizationAcceptanceWithResultMutationVariables,
  SendAuthorizationDenialMutation,
  SendAuthorizationDenialMutationVariables,
  SendDailyDiaryItemMutation,
  SendDailyDiaryItemMutationVariables,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { useGraphMutation } from "../../../../../hooks/useGraphMutation";
import { sendAuthorizationAcceptanceWithResultMutation } from "graphql/mutations/sendAuthorizationAcceptanceWithResult";
import { sendAuthorizationDenialMutation } from "graphql/mutations/sendAuthorizationDenial";
import { reviseDailyDiaryItemMutation } from "graphql/mutations/reviseDailyDiaryItem";
import { sendDailyDiaryItemMutation } from "../graphql/sendDailyDiaryItem";

export const useDailyDiary = (onUpdate?: () => void) => {
  const { t } = useTranslation();

  const [sendDailyDiary, { loading: sendDailyDiaryLoading }] = useGraphMutation<
    SendDailyDiaryItemMutation,
    SendDailyDiaryItemMutationVariables
  >(
    sendDailyDiaryItemMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "dailyDiaryItems" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "dailyDiaryItemsSentByMe" });
        cache.gc();

        onUpdate?.();
      },
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.DailyDiaries.dailyDiary"),
    })
  );

  const [approveDailyDiary, { loading: approveDailyDiaryLoading }] =
    useGraphMutation<
      SendAuthorizationAcceptanceWithResultMutation,
      SendAuthorizationAcceptanceWithResultMutationVariables
    >(
      sendAuthorizationAcceptanceWithResultMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "dailyDiaryItems" });
          cache.evict({ id: "ROOT_QUERY", fieldName: "dailyDiaryItem" });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "dailyDiaryItemsRequiresMyAttention",
          });
          cache.gc();

          onUpdate?.();
        },
      },
      t("common.successMessages.entityApproved", {
        entity: t("Projects.DailyDiaries.dailyDiary"),
      })
    );

  const [rejectDailyDiary, { loading: rejectDailyDiaryLoading }] =
    useGraphMutation<
      SendAuthorizationDenialMutation,
      SendAuthorizationDenialMutationVariables
    >(
      sendAuthorizationDenialMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "dailyDiaryItems" });
          cache.evict({ id: "ROOT_QUERY", fieldName: "dailyDiaryItem" });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "dailyDiaryItemsRequiresMyAttention",
          });
          cache.gc();

          onUpdate?.();
        },
      },
      t("common.successMessages.entityRejected", {
        entity: t("Projects.DailyDiaries.dailyDiary"),
      })
    );

  const [reviseDailyDiary, { loading: reviseDailyDiaryLoading }] =
    useGraphMutation<
      ReviseDailyDiaryItemMutation,
      ReviseDailyDiaryItemMutationVariables
    >(
      reviseDailyDiaryItemMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "dailyDiaryItems" });
          cache.gc();

          onUpdate?.();
        },
      },
      t("Projects.DailyDiaries.draftDailyDiarySuccessfullyCreated")
    );

  return {
    sendDailyDiaryLoading,
    approveDailyDiaryLoading,
    rejectDailyDiaryLoading,
    reviseDailyDiaryLoading,
    sendDailyDiary,
    approveDailyDiary,
    rejectDailyDiary,
    reviseDailyDiary,
  };
};
